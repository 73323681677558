import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import Newsletter from '../components/Newsletter'

const CookiesPage = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query CookiesPageQuery {
      markdownRemark(frontmatter: { path: { eq: "/cookies" } }) {
        frontmatter {
          title
        }
        html
      }
    }
  `)

  return (
    <Page className="p-policy has-fingerPrints">
      <SEO title={markdownRemark.frontmatter.title} />
      <Schema />

      <section className="c-section c-section--policy">
        <div className="c-section__outer container">
          <div className="c-section__inner text-left">
            <div
              className="c-policy"
              dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
            />
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export default CookiesPage
